<script lang="ts">
	import { getContext, onMount } from 'svelte';
	import AutoCompleteResult from '$components/pickup-location/AutoCompleteResult.svelte';
	import { autoComplete } from '$lib/maps/autocomplete';
	import { getSessionToken } from '$lib/maps/session';
	import { PALMA_AIRPORT_PLACE_ID, defaultAutoCompleteResults } from '$lib/maps/defaults';

	import type { AutocompletePrediction } from '$types/autocomplete.types';
	import { PickupMapVariants, type MapVariant } from '$types/map.types';

	import CloseGradientIcon from '$lib/icons/close-gradient-icon.svelte';
	import { clickOutside } from '$lib/actions/clickOutside';
	import { KeyboardEventTypes } from '$types/keyboardEvent.types';
	import { createEventDispatcher } from 'svelte';
	export let value: AutocompletePrediction | undefined;
	export let variant: MapVariant;
	export let swapping = false;
	export let init: AutocompletePrediction | undefined;
	import { locationSelection, resetFrom, resetTo } from '$store/locationSelection';
	import { addToast } from '$store/toast';

	let toast_t = getContext('page-contents-toast-messages') as Record<string, string>;
	let userInput = '';
	let inputElement: HTMLInputElement;
	let isOpen = false;
	let sessionToken: google.maps.places.AutocompleteSessionToken;
	let isMounted = false;
	let autoCompleteResults = defaultAutoCompleteResults;
	let suggestionText = '';
	let autoFocused = false;
	let focusedIndex = -1;
	let dispatch = createEventDispatcher();
	let t = getContext('page-contents-landing') as Record<string, string>;

	onMount(() => {
		isMounted = true;
		// initialize the value to the init value if passes as props
		if (init) {
			value = init;
		}
		getSessionToken().then((token) => (sessionToken = token));
		updateFromValue();
		if (inputElement && PickupMapVariants.includes(variant)) {
			autoFocused = true;
			inputElement.focus();
		}
	});

	$: if (isMounted && userInput.length > 0) {
		if (userInput.trim().length === 0) {
			autoCompleteResults = defaultAutoCompleteResults;
		} else {
			autoComplete(userInput, sessionToken).then(parseAutoCompleteResults).catch(console.debug);
		}
	}

	function parseAutoCompleteResults(results: AutocompletePrediction[] = []) {
		const IGNORELIST = ['airport', 'aeropuerto', 'aeròdrom', 'aeroport'];

		autoCompleteResults = results.filter(
			(item) =>
				!(
					(item.type === 'airport' && item.place_id !== PALMA_AIRPORT_PLACE_ID) ||
					(IGNORELIST.some((ignore) =>
						item.structured_formatting.main_text.toLowerCase().includes(ignore)
					) &&
						item.type !== 'airport')
				)
		);
	}
	async function selectAutoCompleteResult(result: AutocompletePrediction) {
		if (
			variant.toLowerCase().includes('pickup') &&
			result.place_id === $locationSelection.dropoff?.place_id
		) {
			addToast({
				type: 'error',
				message: toast_t.pickup_and_dropoff_location_cannot_be_the_same
			});
			return;
		} else if (
			variant.toLowerCase().includes('dropoff') &&
			result.place_id === $locationSelection.pickup?.place_id
		) {
			addToast({
				type: 'error',
				message: toast_t.pickup_and_dropoff_location_cannot_be_the_same
			});
			return;
		}
		value = result;
		userInput = result.structured_formatting.main_text;
		isOpen = false;
	}

	const handleKeyboardKeys = (event: KeyboardEvent) => {
		if (!isOpen) {
			isOpen = true;
			inputElement.focus();
		}
		if (event.key === KeyboardEventTypes.arrowRight) {
			// check if we have letter to the right of the cursor
			const selectionStart = inputElement.selectionStart ?? 0;
			const length = inputElement.value.length;
			if (selectionStart === length) {
				event.preventDefault();
				inputElement.value = suggestionText;
			}
		}
		if (event.key === KeyboardEventTypes.arrowDown) {
			event.preventDefault();
			focusedIndex = focusedIndex === autoCompleteResults.length - 1 ? 0 : focusedIndex + 1;
		}
		if (event.key === KeyboardEventTypes.arrowUp) {
			event.preventDefault();
			focusedIndex = focusedIndex === 0 ? autoCompleteResults.length - 1 : focusedIndex - 1;
		}
		if (event.key === KeyboardEventTypes.enter) {
			event.preventDefault();
			if (focusedIndex >= 0) {
				selectAutoCompleteResult(autoCompleteResults[focusedIndex]);
			} else if (autoCompleteResults.length > 0) {
				selectAutoCompleteResult(autoCompleteResults[0]);
			}
			isOpen = false;
		}
		if (event.key === KeyboardEventTypes.tab) {
			isOpen = false;
		}
	};

	$: suggestionText = autoCompleteResults[0]?.structured_formatting?.main_text || '';

	$: {
		const escapedUserInput = userInput.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const regex = new RegExp(`^${escapedUserInput}`, 'gi');
		suggestionText = suggestionText.replace(regex, userInput);
		if (!suggestionText.includes(userInput)) {
			suggestionText = '';
		}
	}

	$: {
		if (userInput.length === 0) {
			autoCompleteResults = defaultAutoCompleteResults;
		}
	}

	const updateFromValue = () => {
		if (value) {
			userInput = value.structured_formatting.main_text;
			if (userInput.length > 0)
				autoComplete(userInput, sessionToken).then(parseAutoCompleteResults).catch(console.debug);
			else autoCompleteResults = defaultAutoCompleteResults;
		}
	};

	const resetValue = () => {
		value = {
			place_id: '',
			structured_formatting: {
				main_text: '',
				secondary_text: ''
			},
			type: ''
		};
	};

	$: {
		if (swapping) {
			swapping = false;
			updateFromValue();
		}
	}
</script>

<div
	class="pointer-events-auto! dark:bg-grey-800 rounded-5px min-h-73px relative relative w-full bg-white"
	use:clickOutside={{ enabled: isOpen, callback: () => (isOpen = isOpen ? false : isOpen) }}
>
	<div class="p-3">
		<div class="">
			{PickupMapVariants.includes(variant) ? t.location_selection_from : t.location_selection_to}
		</div>
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div
			class="flex-(row nowrap) dark:bg-grey-700 flex items-center"
			on:click|preventDefault={() => {
				inputElement.focus();
			}}
		>
			<div class=" relative w-full">
				<div
					class="text-grey-500 left-0.2px rubik-regular-base h-1.5rem pointer-events-none absolute top-1/2 -translate-y-1/2 transform overflow-clip"
					class:!hidden={!(userInput.trim().length > 0)}
				>
					{suggestionText}
				</div>
				<input
					class="placeholder:(text-grey-500 rubik-regular-sm leading-5) rubik-regular-base text-grey-800 dark:text-grey-100 dark:bg-grey-700 mr-9 line-clamp-1 w-full focus:outline-none"
					bind:this={inputElement}
					on:keydown={handleKeyboardKeys}
					bind:value={userInput}
					on:change={() => {
						if (value?.structured_formatting.main_text !== userInput) {
							resetValue();
						}
						focusedIndex = -1;
					}}
					on:focus={() => {
						if (autoFocused) {
							autoFocused = false;
							return;
						}
						inputElement.select();
						isOpen = true;
					}}
					on:click={() => {
						if (autoFocused) {
							autoFocused = false;
							return;
						}
						inputElement.select();
						isOpen = true;
					}}
					aria-label={PickupMapVariants.includes(variant)
						? t.location_selection_enter_pickup_address
						: t.location_selection_enter_dropoff_address}
					placeholder={PickupMapVariants.includes(variant)
						? t.location_selection_enter_pickup_address
						: t.location_selection_enter_dropoff_address}
				/>
			</div>
			{#if userInput}
				<button
					class="dark:bg-grey-700 absolute right-5 top-1/2 flex -translate-y-1/2 transform items-center space-x-1.5 rounded-3xl bg-white px-2 py-[1px]"
					on:click|preventDefault={() => {
						userInput = '';
						dispatch('clear');
					}}
					aria-label="Clear"
					tabindex="-1"
				>
					<CloseGradientIcon />
				</button>
			{/if}
		</div>
	</div>
	<div class="absolute left-0 right-0 w-full">
		{#if userInput.length > 0 && autoCompleteResults.length === 0}
			<div class="flex flex-col bg-white p-3">
				<div class="rubik-regular-sm border-grey-200 flex-center border-t p-5 text-red-500">
					No results found
				</div>
			</div>
		{:else if isOpen}
			<div class="flex flex-col bg-white">
				<div class="flex flex-col overflow-hidden">
					{#each autoCompleteResults as result, i}
						<AutoCompleteResult
							isFocused={focusedIndex === i}
							value={result}
							on:click={() => selectAutoCompleteResult(result)}
						/>
					{/each}
				</div>
			</div>
		{/if}
	</div>
</div>
