<script lang="ts">
	import { goto } from '$app/navigation';
	import SwapIcon from '$lib/icons/pickup-details/swap-icon.svelte';
	import { locationSelection, resetFrom, resetTo } from '$store/locationSelection';
	import { LINK_BOOKING, LOCALIZELINK } from '$utils/link';
	import { getContext } from 'svelte';
	import InputLocation from './InputLocation.svelte';
	import type { LANGUAGE } from '$lib/constants/locale';
	import GlowingButton from '$components/atoms/GlowingButton.svelte';
	let lang = getContext('lang') as LANGUAGE;
	let t = getContext('page-contents-landing') as Record<string, string>;

	const handleBooking = () => {
		$locationSelection.isValid = true;
		goto(LOCALIZELINK(lang, LINK_BOOKING));
	};
	let swapping = false;
	let rotationAngle = 0;
	const handleSwap = () => {
		swapping = true;
		[$locationSelection.pickup, $locationSelection.dropoff] = [
			$locationSelection.dropoff,
			$locationSelection.pickup
		];
		if ($locationSelection.pickup?.place_id || $locationSelection.dropoff?.place_id) {
			rotationAngle = (rotationAngle + 180) % 360;
		}
	};
</script>

<div class="relative hidden items-center justify-between gap-5 md:flex">
	<div class="relative flex w-full items-center justify-between gap-5">
		<InputLocation
			bind:value={$locationSelection.pickup}
			variant="pickup"
			bind:swapping
			init={$locationSelection.pickup}
			on:clear={() => {
				resetFrom();
			}}
		/>
		<button
			class="flex-center top-1.5rem absolute left-[calc(50%-16px)] z-40 h-8 w-8 rounded-full bg-white p-2 transition-all duration-300"
			aria-label="Switch pickup and dropoff locations"
			style="box-shadow: 0px 2px 28px 0px rgba(142, 122, 239, 0.45); transform-origin: center; transform: rotate({rotationAngle}deg)"
			on:click={handleSwap}
			tabindex="-1"
		>
			<SwapIcon direction="x" />
		</button>
		<InputLocation
			bind:value={$locationSelection.dropoff}
			variant="dropoff"
			bind:swapping
			on:clear={() => {
				resetTo();
			}}
			init={$locationSelection.dropoff}
		/>
	</div>
	<GlowingButton class="!max-w-189px h-53px relative" on:click={handleBooking}>
		{t.book_transfer_now}
	</GlowingButton>
</div>

<style>
	:global(.cta) {
		position: relative;
		overflow: hidden;
		transition: all 0.9s ease;
		z-index: 1;
	}

	:global(.cta::before) {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(103.99deg, #ab99ff 0%, #7c61ff 87.97%);
		opacity: 0;
		transition: opacity 0.3s ease;
		z-index: -1;
	}

	:global(.cta:hover::before) {
		opacity: 1;
	}

	@keyframes rotate {
		from {
			transform: translateX(-50%) rotate(0deg) !important;
		}
		to {
			transform: translateX(-50%) rotate(180deg) !important;
		}
	}
</style>
